import React from "react";
import { Link } from "gatsby";
import Img from "gatsby-image"

import {
  Container,
  ImageContainer,
  ContentContainer,
  Headline,
  HeadlineLink,
  Byline,
} from './NewsPost.css';

const NewsPost = ({
  author,
  date,
  image,
  link,
  title,
  excerpt,
}) => (
  <Container>
    <ImageContainer>
      <Link to={link}>
        <Img
          fixed={image}
          alt={title}
        />
      </Link>
    </ImageContainer>
    <ContentContainer>
      <HeadlineLink to={link}>
        <Headline dangerouslySetInnerHTML={{__html: title}} />
      </HeadlineLink>
      <Byline>
        Written by {author} on {date}
      </Byline>
      <div dangerouslySetInnerHTML={{ __html: excerpt }} />
    </ContentContainer>
  </Container>
)

export default NewsPost;
