import React from "react";
import { StaticQuery, Link, graphql } from "gatsby";
import Img from "gatsby-image"

import { GroupHeading } from '../../styles/typography.css';
import {
  Container,
  Headline,
  HeadlineLink,
  ImageContainer,
} from './ReviewsPosts.css'

const query = graphql`
  query {
    allWordpressPost(
      sort: { fields: [date] order: DESC }
      filter: {categories: {elemMatch: {slug: {eq: "reviews"}}}}
    ) {
      edges {
        node {
          title
          excerpt
          slug
          author {
            name
          }
          date(formatString: "MMMM DD, YYYY")
          featured_media {
            localFile {
              childImageSharp {
                fixed(width: 400) {
                  ...GatsbyImageSharpFixed
                }
              }
              url
            }
          }
        }
      }
    }
  }
`;

const ReviewsPosts = () => (
  <StaticQuery
    query={query}
    render={data => (
      <Container>
        <GroupHeading>Latest Reviews</GroupHeading>
        <ul>
          {data.allWordpressPost.edges.map(post => (
            <Headline key={post.node.slug}>
              <ImageContainer>
                <Link to={`/post/${post.node.slug}`}>
                  <Img fixed={post.node.featured_media.localFile.childImageSharp.fixed} />
                </Link>
              </ImageContainer>

              <HeadlineLink to={`/post/${post.node.slug}`}>
                {post.node.title}
              </HeadlineLink>
            </Headline>
          ))}
        </ul>
      </Container>
    )}
  />
);

export default ReviewsPosts;
