import styled from "styled-components";
import { Link } from "gatsby";

import {
  Heading2,
} from '../../styles/typography.css';

import { media } from '../../styles/mixins.css';

export const Container = styled.li`
  display: flex;
  flex-direction: column;
  margin: 1rem 0;
  padding-bottom: 1rem;
  border-bottom: 1px solid #ccc;

  @media ${media.medium} {
    flex-direction: row;
  }
`;

export const ImageContainer = styled.div`
  width: 100%;
  height: 12rem;
  overflow: hidden;
  position: relative;
  flex-shrink: 0;

  @media ${media.medium} {
    max-width: 18rem;
  }

  .gatsby-image-wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

export const ContentContainer = styled.div`
  margin: 1rem 0 0;

  @media ${media.medium} {
    margin: 0 0 0 1rem;
  }
`;

export const HeadlineLink = styled(Link)`
  text-decoration: none;
  color: black;
  transition: 0.1s;

  &:hover, &:focus {
    color: #03a9f4;
  }
`

export const Headline = styled.h2`
  ${Heading2}
  margin-top: 0;
  margin-bottom: 0.125rem;
`;

export const Byline = styled.div`
  font-size: 0.75rem;
`;
