import React from "react";

import Layout from "../components/Layout/Layout";
import NewsPosts from "../components/NewsPosts/NewsPosts";
import ReviewsPosts from "../components/ReviewsPosts/ReviewsPosts";
import SEO from "../components/seo";

const IndexPage = () => (
  <Layout page="home">
    <SEO title="Comic Book Wiki" keywords={[
      `comic books`,
      `news`,
      `database`
    ]} />
    <NewsPosts />
    <ReviewsPosts />
  </Layout>
)

export default IndexPage;
