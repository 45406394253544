import styled from "styled-components";

import { media } from '../../styles/mixins.css';

export const Container = styled.div`
  background: #ffffff;
  padding: 0.875rem;

  @media ${media.medium} {
    padding-right: 1rem;
  }
`;
