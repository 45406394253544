import React from "react";
import { StaticQuery, graphql } from "gatsby";

import NewsPost from "../NewsPost/NewsPost";
import { GroupHeading } from '../../styles/typography.css';
import { Container } from './NewsPosts.css';

const query = graphql`
  query {
    allWordpressPost(
      sort: { fields: [date] order: DESC }
      filter: {categories: {elemMatch: {slug: {eq: "news"}}}}
    ) {
      edges {
        node {
          title
          excerpt
          slug
          author {
            name
          }
          date(formatString: "MMMM DD, YYYY")
          featured_media {
            localFile {
              childImageSharp {
                fixed(width: 400) {
                  ...GatsbyImageSharpFixed
                }
              }
              url
            }
          }
        }
      }
    }
  }
`;

const NewsPosts = () => (
  <StaticQuery
    query={query}
    render={data => (
      <Container>
        <GroupHeading>Latest News</GroupHeading>
        <ul>
          {data.allWordpressPost.edges.map((post) => (
            <NewsPost
              key={post.node.slug}
              author={post.node.author.name}
              date={post.node.date}
              image={post.node.featured_media.localFile.childImageSharp.fixed}
              link={`/post/${post.node.slug}`}
              title={post.node.title}
              excerpt={post.node.excerpt}
            />
          ))}
        </ul>
      </Container>
    )}
  />
);

export default NewsPosts;
