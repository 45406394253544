import styled from "styled-components";
import { Link } from "gatsby";

import { media } from '../../styles/mixins.css';

export const Container = styled.div`
  @media ${media.medium} {
    flex-shrink: 0;
    width: 20rem;
    padding-left: 1rem;
  }
`;

export const Headline = styled.li`
  font-size: 1rem;
  margin: 1rem 0;
`;

export const HeadlineLink = styled(Link)`
  text-decoration: none;
  color: black;
  transition: 0.1s;

  &:hover, &:focus {
    color: #03a9f4;
  }
`

export const ImageContainer = styled.div`
  width: 100%;
  height: 10rem;
  margin-bottom: 0.5rem;
  overflow: hidden;
  position: relative;
  flex-shrink: 0;

  .gatsby-image-wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;
